import betaLogo from './assets/bh-logo.png'
// import imageTen from './assets/number-ten.jpg'
import imageNine from './assets/number-nine.jpg'
import imageEight from './assets/number-eight.jpg'
import imageSeven from './assets/number-seven.jpg'
import imageSix from './assets/number-six.jpg'
// import imageFive from './assets/number-five.jpg'
import imageOne from './assets/number-one.jpg'
import imageThree from './assets/number-three.jpg'
import imageTwo from './assets/number-two.jpg'
import imageFour from './assets/number-four.jpg'
import betaImage from './assets/iStock-458587075.jpg'
import goalImage from './assets/iStock-1146831636.jpg'
import wantImage from './assets/woman-and-child.jpeg'
import { Link } from 'react-scroll'
import { Link as RouterLink } from 'react-router-dom'
// import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import './App.css';
import { useState } from 'react'
// import { createRef, useLayoutEffect } from 'react'

function App() {
  const [ showOneText, setShowOneText ] = useState(false)
  const [ showTwoText, setShowTwoText ] = useState(false)
  const [ showThreeText, setShowThreeText ] = useState(false)
  const [ showFourText, setShowFourText ] = useState(false)
  const [ showSixText, setShowSixText ] = useState(false)
  const [ showSevenText, setShowSevenText ] = useState(false)
  const [ showEightText, setShowEightText ] = useState(false)

  const onLoaded = () => {
    window.addEventListener("scroll", (event) => {
        let scroll = window.scrollY;
        if(scroll > 130) {
            document.querySelector('.nav').classList.add('navigator')
        } else {
            document.querySelector('.nav').classList.remove('navigator')
        }
    });
  }

  return (
    <div onLoad={() => onLoaded()} className="gt-main">
      <section className="above-fold">
        <nav className="nav">
          <ul>
            <li>
              <Link
                activeClass="active" to="the-goal" spy={true} smooth={true} 
                offset={-75} duration={500}
              >
                The Goal
              </Link>
            </li>
            <li>
              <Link
                activeClass="active" to="about-beta" spy={true} smooth={true} 
                offset={-120} duration={500}>
                About BetaHealth
              </Link>
            </li>
            <li>
              <Link
                activeClass="active" to="want-beta" spy={true} smooth={true} 
                offset={-5} duration={500}>
                Want BetaHealth?
              </Link></li>
          </ul>
        </nav>
        <div onClick={() => console.log(window.scrollY)} className="carousel-wrapper">
          <div className="carousel">
            <div className="center-point">
              <div className="center-text">
                <p className="top-text">#IWD2021</p>
                <p className="main-text mb-1">
                  <span>To all the Women Striving, against</span>
                  <span>the odds, for a Better Life</span>
                </p>
                <p className="buzz-text">Give Her Beta Health</p>
              </div>
              <div className="img img-one" onMouseOver={() => setShowOneText(true)} onMouseOut={() => setShowOneText(false)}>
                <img src={imageOne} alt="first iwd woman"/>
                <div className="orange-cover"></div>
              </div>
              {showOneText && <div className="showOneText">
                {/* <i class="fas fa-info-circle" style={{ color: '#FF581A' }}></i> */}
                <p><span>#BetaHealthForHer</span> Goods are negotiable access to Beta Health shouldn’t be.</p>
              </div>}

              <div className="img img-two" onMouseOver={() => setShowTwoText(true)} onMouseOut={() => setShowTwoText(false)}>
                <img src={imageTwo} alt="second iwd woman"/>
                <div className="orange-cover"></div>
                {/* style={{ border: '1px solid blue'}}  */}
              </div>
              {showTwoText && <div className="showTwoText">
                {/* <i class="fas fa-info-circle" style={{ color: '#FF581A' }}></i> */}
                <p><span>#BetaHealthForHer</span> Three-quarters of farmers in Nigeria are women</p>
              </div>}

              <div className="img img-three" onMouseOver={() => setShowThreeText(true)} onMouseOut={() => setShowThreeText(false)}>
                <img src={imageThree} alt="third iwd woman"/>
                <div className="orange-cover"></div>
              </div>
              {showThreeText && <div className="showThreeText">
                {/* <i class="fas fa-info-circle" style={{ color: '#FF581A' }}></i> */}
                <p><span>#BetaHealthForHer</span> Help her to keep challenging for a Better World.</p>
              </div>}

              <div className="img img-four" onMouseOver={() => setShowFourText(true)} onMouseOut={() => setShowFourText(false)}>
                <img src={imageFour} alt="fourth iwd woman"/>
                <div className="orange-cover"></div>
              </div>
              {showFourText && <div className="showFourText">
                {/* <i class="fas fa-info-circle" style={{ color: '#FF581A' }}></i> */}
                <p><span>#BetaHealthForHer</span> You are giving 1000 women free healthcare coverage for a year</p>
              </div>}

              {/* <div className="img img-five">
                <img src={imageFive} alt="fifth iwd woman"/>
                <div className="orange-cover"></div>
              </div> */}

              <div className="img img-six" onMouseOver={() => setShowSixText(true)} onMouseOut={() => setShowSixText(false)}>
                <img src={imageSix} alt="sixth iwd woman"/>
                <div className="orange-cover"></div>
              </div>
              {showSixText && <div className="showSixText">
                {/* <i class="fas fa-info-circle" style={{ color: '#FF581A' }}></i> */}
                <p><span>#BetaHealthForHer</span> Give them one less thing to worry about.</p>
              </div>}

              <div className="img img-seven" onMouseOver={() => setShowSevenText(true)} onMouseOut={() => setShowSevenText(false)}>
                <img src={imageSeven} alt="seventh iwd woman"/>
                <div className="orange-cover"></div>
              </div>
              {showSevenText && <div className="showSevenText">
                {/* <i class="fas fa-info-circle" style={{ color: '#FF581A' }}></i> */}
                <p><span>#BetaHealthForHer</span> In between the hustle and bustle is a woman that needs cover</p>
              </div>}

              <div className="img img-eight" onMouseOver={() => setShowEightText(true)} onMouseOut={() => setShowEightText(false)}>
                <img src={imageEight} alt="eight iwd woman"/>
                <div className="orange-cover"></div>
              </div>
              {showEightText && <div className="showEightText">
                {/* <i class="fas fa-info-circle" style={{ color: '#FF581A' }}></i> */}
                <p><span>#BetaHealthForHer</span> Nearly half of all small businesses in Nigeria are run by women</p>
              </div>}

              <div className="img img-nine">
                <img src={imageNine} alt="ninth iwd woman"/>
                <div className="orange-cover"></div>
              </div>

              {/* <div className="img img-ten">
                <img src={imageTen} alt="tenth iwd woman"/>
                <div className="orange-cover"></div>
              </div> */}
            </div>
          </div>
          <div className="top-cover">
            <div className="center-point-text">
              <p className="top-text">#IWD2021</p>
              <p className="main-text mb-1">
                <span>To all the Women Striving, against</span>
                <span>the odds, for a Better Life</span>
              </p>
              <p className="buzz-text">Give Her Beta Health</p>
            </div>
          </div>
          
        </div>
      </section>

      <section className="beta-film">
        <div className="beta-film-container">
          <p className="title mb-1">Every Woman Deserves Beta Health</p>
          <div className="media-box mb-2">
            <img src={betaImage} alt="watch the beta health film"/>
                <div className="black-cover"></div>
            <p className="media-overlay">
              <span>Watch the Film </span>
              {/* <i className="material-icons">play</i> */}
              <i className="far fa-play-circle"></i>
            </p>
          </div>
          <p className="description ps-half">
            Everyone deserves access to affordable healthcare coverage, but most 
            women who work in the informal sector in Nigeria have never had basic 
            healthcare coverage in their lifetimes. In honour of International Women’s 
            Day, we #choosetochallenge this by providing 1,000 self-employed women in 
            low income and informal jobs, free access to healthcare coverage for a year.
          </p>
        </div>
      </section>

      <section className="nomination bg-grey">
        <div className="nomination-container">
          <p className="title mb-1">We need your help</p>
          <p className="description mb-2 ps-half">
            <span className="enbolden">You can give 1,000 women free healthcare. </span>
            Kindly nominate any woman in the informal sector who needs basic healthcare 
            coverage. You know these women already. They are the petty traders and artisans 
            you love to patronise in the markets or across your street, and the small time 
            food retailers who make and sell finger foods and other delicacies near your 
            office or by the junction. 
          </p>
          <div className="button outline">
            <RouterLink to={'/give-her-betahealth'}>Nominate Her</RouterLink>
          </div>
        </div>
      </section>

      <section id="the-goal" className="the-goal">
        <div className="the-goal-container">
          <p className="title mb-1">The Goal</p>
          <div className="media-box mb-2">
            <img src={goalImage} alt="the goal of beta health"/>
            <div className="purple-cover"></div>
            <img src={betaLogo} className="media-overlay" alt="beta health logo" />
          </div>
          <p className="description mb-3 ps-half">
          Empowering 1,000 women is simply scratching the surface of what we aim to achieve 
          with Beta Health. We want more and more people to access affordable health care 
          coverage easily, and you can help with that too. With N500 per month or N6,000 
          annually, you can buy a BetaHealth coverage plan for anyone of your choice and they 
          will have access to basic and essential healthcare coverage in hospitals nationwide. 
          </p>
          <p id="about-beta" className="title mb-1">About BetaHealth</p>
          <p className="description mb-2 ps-half">
          Beta Health is a low cost healthcare plan that gives access to basic and essential 
          healthcare services such as general consultations, treatment for malaria, antenatal 
          care, amongst others in hospitals nationwide.
          </p>
          <div className="button outline">
            <a href="https://www.gtbank.com/beta-health">Learn More</a>
          </div>
        </div>
      </section>

      <section id="want-beta" className="want-beta bg-grey">
        <div className="want-beta-container">
          <div className="media-box mb-2">
            <img src={wantImage} alt="the goal of beta health"/>
          </div>
          <p className="title mb-1">Want Beta Health for Yourself?</p>
          <p className="description mb-2 ps-half">
          If you are a GTBank customer, just dial *737*52*500*50#. No paper forms or branch 
          visits required. 
          </p>
          <p className="description ps-half">
          It’s also simple if you are not a GTBank customer. Just dial *737*0# to open a 
          GTBank account instantly or download GTWorld on the App  or Play Store.
          </p>
        </div>
      </section>

      <footer>
        <div className="content ps-half">
          <p>Copyright(c) {new Date().getFullYear()}. Give Her Betahealth. All Rights Reserved</p>
          <p>Powered by Guaranty Trust Bank Plc</p>
        </div>
      </footer>

    </div>
  );
}

export default App;
