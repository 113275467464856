import React, { useState, useEffect } from 'react'
import axios from 'axios'
import './Nominations.css'

const Nominations = () => {
    const [nominations, setNominations] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        getNominations()
    }, [])

    const getNominations = async () => {
        setLoading(true)
        try {
            await axios.get('https://kingsguard.herokuapp.com/nomination')
            .then(res => {
                setNominations(res.data.Nominations)
                setLoading(false)
            })
        } catch(e) {
            console.log(e)
        }
    }

    return (
        <div className="nominations">
            <div className="main-title">
                <p className="heading">List of Nominations</p>
                <p className="tagline">Betahealth. Powered by GTBank</p>
                {
                    nominations &&
                    <button className="btn-general2" onClick={getNominations}>
                        {loading ? 'Loading...' : 'Refresh List'}
                    </button>
                }
            </div>
            <div className="nominations-container">
                {
                    nominations 
                        ? nominations.map((nomination, key) => (
                            <div className="single-nomination">
                                <div className="nominee">
                                    <p className="heading-text">Nominee Details</p>
                                    <p><span>Name:</span> {nomination.nomineeLastName} {nomination.nomineeFirstName}</p>
                                    <p><span>Phone Number:</span> {nomination.nomineePhoneNumber}</p>
                                    <p><span>Business Type:</span> {nomination.nomineeTypeOfBusiness}</p>
                                    <p><span>Street Name:</span> {nomination.nomineeStreetName}</p>
                                    <p><span>State:</span> {nomination.nomineeState}</p>
                                    <p><span>Age Range:</span> {nomination.nomineeAgeRange}</p>
                                    {nomination.nomineeOtherDetails && <p><span>Other Details:</span> {nomination.nomineeOtherDetails}</p>}
                                </div>
                                <div className="nominator">
                                    <p className="heading-text">Nominator Details</p>
                                    <p><span>Name:</span> {nomination.nominatorLastName} {nomination.nominatorFirstName}</p>
                                    <p><span>Phone Number:</span> {nomination.nominatorPhoneNumber}</p>
                                    <p><span>Email Address:</span> {nomination.nominatorEmailAddress}</p>
                                </div>
                            </div>
                        ))
                        : <p className="loading">Loading..</p>
                }
                
                {/* <div className="x-scroll">
                    <ul className="table-header">
                        <li>Nominee First Name</li>
                        <li>Nominee Last Name</li>
                        <li>Nominee Phone Number</li>
                        <li>Nominee Business Type</li>
                        <li>Nominee Street Name</li>
                        <li>Nominee State</li>
                        <li>Nominee Age Range</li>
                        <li>Nominee Other Details</li>
                        <li>Nominator First Name</li>
                        <li>Nominator Last Name</li>
                        <li>Nominator Phone Number</li>
                        <li>Nominator Email Address</li>
                    </ul>
                    {
                        nominations
                            ? nominations.map((nomination, key) => (
                                <ul className="table-row" key={key}>
                                    <li>{nomination.nomineeFirstName}</li>
                                    <li>{nomination.nomineeLastName}</li>
                                    <li>{nomination.nomineePhoneNumber}</li>
                                    <li>{nomination.nomineeTypeOfBusiness}</li>
                                    <li>{nomination.nomineeStreetName}</li>
                                    <li>{nomination.nomineeState}</li>
                                    <li>{nomination.nomineeAgeRange}</li>
                                    <li>{nomination.nomineeOtherDetails}</li>
                                    <li>{nomination.nominatorFirstName}</li>
                                    <li>{nomination.nominatorLastName}</li>
                                    <li>{nomination.nominatorPhoneNumber}</li>
                                    <li>{nomination.nominatorEmailAddress}</li>
                                </ul>
                            ))
                            : <p className="loading">Loading..</p>
                    }
                </div> */}
            </div>
        </div>
    )
}

export default Nominations
