import { useState } from 'react'
import './Form.css'
import { TwitterShareButton } from 'react-twitter-embed'
import axios from 'axios'
import { Link } from 'react-router-dom'
// import headerImage from './assets/iStock-10397642367.jpg'

// export const ScrollToTopOnMount = () => {
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   return null;
// }

const useInputValue = initialValue => {
    const [ value, setValue ] = useState(initialValue)

    return {
        value,
        type: 'text',
        onChange: e => setValue(e.target.value),
        setvalue: val => setValue(val)
        // clearInput: () => setValue('')
    }
}

const useNumberValue = initialValue => {
    const [ value, setValue ] = useState(initialValue)

    return {
        value,
        type: 'number',
        onChange: e => setValue(e.target.value),
        setvalue: val => setValue(val)
        // clearInput: () => setValue('')
    }
}

const Form = () => {
    const { setvalue: setFName, ...nomineeFirstName } = useInputValue('')
    const { setvalue: setLName, ...nomineeLastName } = useInputValue('')
    const { setvalue: setPhoneNumber, ...nomineePhoneNumber } = useNumberValue('')
    const { setvalue: setBizType, ...nomineeBusinessType } = useInputValue('')
    const { setvalue: setAddress, ...nomineeAddress } = useInputValue('')
    const { setvalue: setNomState, ...nomineeState } = useInputValue('')
    const { setvalue, ...nomineeAgeRange } = useInputValue('')
    const { setvalue: setDetails, ...nomineeDetails } = useInputValue('')
    const { setvalue: setFirstName, ...nominatorFirstName } = useInputValue('')
    const { setvalue: setLastName, ...nominatorLastName } = useInputValue('')
    const { setvalue: setNomPhone, ...nominatorPhoneNumber } = useNumberValue('')
    const { setvalue: setEmail, ...nominatorEmailAddress } = useInputValue('')

    const BASE_URL = 'https://kingsguard.herokuapp.com'
    const [ submitted, setSubmitted ] = useState(false)
    const [ ageRange, setAgeRange ] = useState(false)
    const [ checked, setChecked ] = useState(false)
    const [ errorMsg, setErrorMsg ] = useState(null)
    const [ loading, setLoading ] = useState(false)
    const [ shareMessage ] = useState('I just finished nominating for the #BetaHealth rewards, visit https://bit.ly/3eeFvxt to Give Her BetaHealth')

    const onChangeValue = e => {
        switch(e.target.value) {
            case 'agree':
                setChecked(true)
                break
            case 'disagree':
                setChecked(false)
                break
            default:
                setChecked(false)
        }
    }

    const submitForm = async e => {
        e.preventDefault();
        await setLoading(true)
        if(
            nomineeFirstName.value.length &&
            nomineeLastName.value.length &&
            String(nomineePhoneNumber.value.length) >= 10 &&
            nomineeBusinessType.value.length &&
            nomineeAddress.value.length &&
            nomineeState.value.length &&
            nomineeAgeRange.value.length &&
            nominatorFirstName.value.length &&
            nominatorLastName.value.length &&
            String(nominatorPhoneNumber.value.length) >= 10 &&
            nominatorEmailAddress.value.includes('@') &&
            checked
        ) {
            const nomineeData = {
                nominatorEmailAddress: nominatorEmailAddress.value,
                nominatorFirstName: nominatorFirstName.value,
                nominatorLastName: nominatorLastName.value,
                nominatorPhoneNumber: String(nominatorPhoneNumber.value),
                nomineeAgeRange: nomineeAgeRange.value,
                nomineeFirstName: nomineeFirstName.value,
                nomineeLastName: nomineeLastName.value,
                nomineePhoneNumber: String(nomineePhoneNumber.value),
                nomineeState: nomineeState.value,
                nomineeTypeOfBusiness: nomineeBusinessType.value,
                nomineeOtherDetails: nomineeDetails.value,
                nomineeStreetName: nomineeAddress.value
            }

            axios
                .post(`${BASE_URL}/nomination`, nomineeData)
                .then(res => {
                    if(res.data.createdNomination) setSubmitted(true)
                    setLoading(false)
                }, err => {
                    console.log(err.message)
                    setErrorMsg(err.message)
                    setTimeout(() => {
                        setErrorMsg(null)
                    }, 1500)
                })
        } else {
            setLoading(false)
            if(String(nomineePhoneNumber.value).length < 10 || String(nominatorPhoneNumber.value).length < 10) {
                setErrorMsg('Please enter a valid number')
                setTimeout(() => {
                    setErrorMsg(null)
                }, 1500)
                return
            }
            if(!nominatorEmailAddress.value.includes('@')) {
                setErrorMsg('Please enter a valid email address')
                setTimeout(() => {
                    setErrorMsg(null)
                }, 1500)
                return
            }
            if(!checked) {
                setErrorMsg('Please agree to our terms to submit the form')
                setTimeout(() => {
                    setErrorMsg(null)
                }, 1500)
                return
            }
            setErrorMsg('Please complete the form to submit')
            setTimeout(() => {
                setErrorMsg(null)
            }, 1500)
        }
    }

    const clearAllInputs = () => {
        setFName('')
        setLName('')
        setPhoneNumber('')
        setBizType('')
        setAddress('')
        setNomState('')
        setvalue('')
        setDetails('')
        setFirstName('')
        setLastName('')
        setNomPhone('')
        setEmail('')
        setChecked(false)
        setSubmitted(false)
    }

    return (
        <div className="gt-form">
            {/* <ScrollToTopOnMount /> */}
            <header>
                <div className="orange-cover"></div>
                <div className="header-box">
                    <p className="page-heading">Give Her Beta Health</p>
                </div>
            </header>

            {
            submitted
            ? <section>
                <div className="pre-form">
                    <p className="header-text" style={{ textAlign: 'center', color: 'green' }}>
                    <i className="far fa-check-circle" style={{ color: 'green', fontSize: '2.5rem'}}></i><br />
                        Nomination submitted successfully
                    </p>
                </div>
                <div className="pre-form" style={{ marginTop: '1rem', textAlign: 'center' }}>
                    <p style={{ marginBottom: '0.5rem' }}>{shareMessage}</p>
                    <TwitterShareButton
                        url={' '}
                        options={{ text: `${shareMessage}`, via: 'gtbank', size: 'large' }}
                    />
                </div>
                <div className="pre-form" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Link to='/'><button className="btn-general">Go To Home</button></Link>
                    <button className="btn-general" onClick={() => clearAllInputs()}>Nominate Another</button>
                </div>
            </section> 
            
            :<section className="form-container">
                <form className="nomination-form" onSubmit={submitForm}>
                    <div className="pre-form">
                        <p className="header-text">
                            Beta Health will give the woman you nominate coverage for basic and 
                            essential health needs such as general consultations, treatment for 
                            malaria and infections, antenatal care, amongst others, in hospitals 
                            nationwide.
                        </p>
                    </div>
                    <div className="nominee">
                        <p className="header mb-1">1. Nominee Information</p>
                        <p className="header-text mb-2">Your nominee should be a self-employed woman in 
                        the informal sector e.g petty traders, food vendors, tailors, hair-dressers 
                        and other artisans.</p>
                        <div className="form-rows mb-2">
                            <p className="label mb-1">Your Nominee’s Name</p>
                            <div className="inputs">
                                <input { ...nomineeFirstName } placeholder="First Name" />
                                <input { ...nomineeLastName } placeholder="Last Name" />
                            </div>
                        </div>
                        <div className="form-rows mb-2">
                            <div className="single-inputs">
                                <div className="input phone-number">
                                    <p className="label mb-1">Nominee’s Phone Number</p>
                                    <input { ...nomineePhoneNumber } placeholder="Phone" />
                                    <span>+234</span>
                                </div>
                                <div className="input">
                                    <p className="label mb-1">Type of Business</p>
                                    <input { ...nomineeBusinessType } placeholder="i.e Tomato Seller" />
                                </div>
                            </div>
                        </div>
                        <div className="form-rows mb-2">
                            <p className="label mb-1">Nominee’s Address (Or you can simply enter the 
                            nominee’s business location)</p>
                            <div className="inputs">
                                <input { ...nomineeAddress } placeholder="Street Name" />
                                <input { ...nomineeState } placeholder="State" />
                            </div>
                        </div>
                        <div className="form-rows mb-2">
                            <p className="label mb-1">Age Range</p>
                            <div className="age-range">
                                <input { ...nomineeAgeRange } onClick={() => setAgeRange(!ageRange)} readOnly placeholder="Select age bracket" />
                                <i className="fas fa-angle-down"></i>
                                {ageRange && <div className="drop-down">
                                    <p onClick={() => { setvalue('18 - 35'); setAgeRange(false)}}>18 - 35</p>
                                    <p onClick={() => { setvalue('36 - 60'); setAgeRange(false)}}>36 - 60</p>
                                    <p onClick={() => { setvalue('Above 60'); setAgeRange(false)}}>Above 60</p>
                                </div>}
                            </div>
                        </div>
                        <div className="form-rows">
                            <p className="label mb-1">Other details (if any)</p>
                            <div className="single-input">
                                <input { ...nomineeDetails } placeholder="Tell us why you are nominating 
                                her for Beta Health" />
                            </div>
                        </div>
                    </div>
                    <div className="nominator">
                        <p className="header mb-1">2. Nominator's Information</p>
                        <p className="header-text mb-2">We would like to keep you informed about 
                        your nomination.</p>
                        <div className="form-rows mb-2">
                            <p className="label mb-1">Your Name</p>
                            <div className="inputs">
                                <input { ...nominatorFirstName } placeholder="First Name" />
                                <input { ...nominatorLastName } placeholder="Last Name" />
                            </div>
                        </div>
                        <div className="form-rows">
                            <div className="single-inputs">
                                <div className="input phone-number">
                                    <p className="label mb-1">Your Phone Number</p>
                                    <input { ...nominatorPhoneNumber } placeholder="Phone" />
                                    <span>+234</span>
                                </div>
                                <div className="input">
                                    <p className="label mb-1">Your Email Address</p>
                                    <input { ...nominatorEmailAddress } placeholder="Email Address" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="acknowledge">
                        <p className="header mb-1">3. Acknowledgement</p>
                        <p className="header-text mb-2">I hereby agree that the information provided 
                        on this entry is true and accurate to the best of my knowledge.</p>
                        <div className="form-rows">
                            <div className="inputs" onChange={onChangeValue}>
                                <div className="radio">
                                    <input type="radio" value="agree" name="agreement" />
                                    <p>Agree</p>
                                </div>
                                {/*  onClick={() => { setChecked(true); console.log(checked)}} */}
                                <div className="radio">
                                    <input type="radio" value="disagree" name="agreement" />
                                    <p>Disagree</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="policies">
                        <div className="policy-list">
                            <div className="policy mb-3">
                                <p className="policy-title">Nomination Policy</p>
                                <p>All nominations are strictly confidential and subject to a shortlist 
                                    process which will be carried out by Guaranty Trust Bank Plc. You 
                                    are submitting a request to nominate a woman for Beta Health Coverage, 
                                    which will be wholly paid for by the Bank, for a year. The nominees 
                                    that will be considered in this campaign include self-employed women 
                                    with low-paying jobs in the informal sector. These women should also 
                                    be above the age of 18.</p>
                            </div>
                            <div className="policy">
                                <p className="policy-title">Privacy Policy</p>
                                <p>We take your privacy seriously. In accordance with NDPR, GDPR and 
                                    other applicable regulations, completing this nomination indicates 
                                    your consent to the processing of your personal data by Guaranty 
                                    Trust Bank Plc., its subsidiaries and partners as detailed in our 
                                    Privacy Policy.</p>
                            </div>
                        </div>
                    </div>
                    <div className="sub-button">
                        <div className="submit-button">
                            {errorMsg && <p style={{ textAlign: 'center', marginBottom: '0.5rem', color: 'red' }}>
                                {errorMsg}
                            </p>}
                            {
                                loading 
                                    ? <p className="form-loading">Hold on a moment! Submitting form..</p>
                                    : <button type="submit">Submit Nomination</button>
                            }
                        </div>
                    </div>
                </form>
            </section>}
            <p className="nav"></p>
            <footer>
                <div className="content ps-half">
                    <p>Copyright(c) {new Date().getFullYear()}. Give Her Betahealth. All Rights Reserved</p>
                    <p>Powered by Guaranty Trust Bank Plc</p>
                </div>
            </footer>
        </div>
    )
}

export default Form
